import React from "react";

function Brands() {
  return (
    <div className="brand-style-one-area default-padding-top text-center">
      <div className="container">
        <div className="brand-heading">
          <div className="row">
            <h3>
              Trusted By <strong>50+ </strong> Clients
            </h3>
          </div>
        </div>

        <div className="brand-style-one">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="brand5col swiper">
                <div className="swiper-wrapper align-center">
                  <div className="swiper-slide">
                    <img src="assets/img/brand/veel.png" alt="Thumb" />
                  </div>
                  <div className="swiper-slide">
                    <img src="assets/img/brand/nexus.webp" alt="Thumb" />
                  </div>
                  <div className="swiper-slide">
                    <img src="assets/img/brand/whitepays.png" alt="Thumb" />
                  </div>
                  <div className="swiper-slide d-none d-md-block">
                    <img src="assets/img/brand/takenote.png" alt="Thumb" />
                  </div>
                  <div className="swiper-slide d-none d-md-block">
                    <img src="assets/img/brand/cardclub.png" alt="Thumb" />
                  </div>
                  <div className="swiper-slide">
                    <img src="assets/img/brand/hqre.png" alt="Thumb" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brands;
