import React from "react";

function Banner() {
  return (
    <div id="home" className="banner-style-one-area default-padding">
      <div className="container">
        <div className="banner-top">
          <div className="row align-center">
            <div className="col-lg-7 pr-50 pr-md-15 pr-xs-15">
              <div className="information">
                <div className="content">
                  <h2>
                    Hey{" "}
                    <img
                      src="assets/img/shape/harnd.png"
                      alt="imagee not found"
                    />{" "}
                    , I'm <br />
                    Umair Abbas
                  </h2>
                  <p>
                    As a Full-Stack Web and App Developer based in Pakistan, I'm{" "}
                    <br /> highly passionate and committed to my craft.
                  </p>
                </div>
                <div className="profile-grid">
                  <ul>
                    <li>
                      <a className="smooth-menu" href="#services">
                        Services
                        <div className="icon-list">
                          <img
                            src="assets/img/icon/react.png"
                            alt="react"
                          />
                          <img
                            src="assets/img/icon/python.png"
                            alt="python"
                          />
                          <img
                            src="assets/img/icon/wordpress.png"
                            alt="wordpress"
                          />
                          <img
                            src="assets/img/icon/web-design.png"
                            alt="web design"
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a className="smooth-menu" href="#portfolio">
                        Projects
                        <img
                          src="assets/img/shape/laptop.png"
                          alt="Imagee Not Found"
                        />
                      </a>
                    </li>
                    <li>
                      <a className="smooth-menu" href="#about">
                        About
                        <strong>A</strong>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="thumb">
                <img
                  src="assets/img/illustration/6.png"
                  alt="Imagee Not Found"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
