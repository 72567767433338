import React from "react";
import "swiper/swiper-bundle.css";

function Testimonials() {
  return (
    <div className="testimonial-style-one-area relative" id="testimonials">
      <div className="container">
        <div className="testimonial-style-one-box">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h4 className="sub-title">Testimonials</h4>
                <h2 className="title">Clients Feedback</h2>
                <div className="devider"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="testimonial-carousel text-center swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonial-style-one">
                      <div className="item">
                        <div className="thumb">
                          <img
                            src="assets/img/team/15.jpg"
                            alt="Imagee Not Found"
                          />
                        </div>
                        <div className="content">
                          <p>
                            "I enjoyed my experience with Umair. He is always
                            available and was patient in the process to develop
                            my website. I will continue working with him in the
                            future."
                          </p>
                        </div>
                        <div className="provider">
                          <div className="rating">
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </div>
                          <div className="info">
                            <h4>Jason Gershonovitch </h4>
                            <span>CEO - Ducliar Foundation</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonial-style-one">
                      <div className="item">
                        <div className="thumb">
                          <img
                            src="assets/img/team/14.jpg"
                            alt="Imagee Not Found"
                          />
                        </div>
                        <div className="content">
                          <p>
                            "Umair Abbas is fast smart and very helpful. my
                            second job and I will probably make new ones. Thank
                            you!!!"
                          </p>
                        </div>
                        <div className="provider">
                          <div className="rating">
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </div>
                          <div className="info">
                            <h4>Sarah Dunleavy</h4>
                            <span>UI Designer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
