import React from "react";

function Services() {
  return (
    <div
      id="services"
      className="services-style-one-area default-padding-top mb--50"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="left-heading mb-50">
              <h4 className="sub-title">What i do</h4>
              <h2 className="title">My Specializations</h2>
              <div className="devider"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="service-style-one col-lg-6 col-md-6">
            <div className="service-style-one-item">
              <div className="thumb">
                <img src="assets/img/icon/web-design.png" alt="Icon" />
              </div>
              <div className="info">
                <h4>
                  <a
                    href="##"
                    data-bs-toggle="modal"
                    data-bs-target="#serviceSingleModal"
                  >
                    Website design
                  </a>
                </h4>
                <p>
                  Continue indulged speaking the was horrible for domestic
                  position. Seeing get rather her you not esteem men settle is
                  genius take excuse. Deal say over you age comparison new ham
                  melancholy.
                </p>
                <div className="bottom">
                  <span>28 Projects</span>
                  <a
                    href="##"
                    data-bs-toggle="modal"
                    data-bs-target="#serviceSingleModal"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="service-style-one col-lg-6 col-md-6">
            <div className="service-style-one-item">
              <div className="thumb">
                <img src="assets/img/icon/app-development.png" alt="Icon" />
              </div>
              <div className="info">
                <h4>
                  <a
                    href="##"
                    data-bs-toggle="modal"
                    data-bs-target="#serviceSingleModal"
                  >
                    App Development
                  </a>
                </h4>
                <p>
                  Continue indulged speaking the was horrible for domestic
                  position. Seeing get rather her you not esteem men settle is
                  genius take excuse. Deal say over you age comparison new ham
                  melancholy.
                </p>
                <div className="bottom">
                  <span>1K Projects</span>
                  <a
                    href="##"
                    data-bs-toggle="modal"
                    data-bs-target="#serviceSingleModal"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="service-style-one col-lg-6 col-md-6">
            <div className="service-style-one-item">
              <div className="thumb">
                <img src="assets/img/icon/camera.png" alt="Icon" />
              </div>
              <div className="info">
                <h4>
                  <a
                    href="##"
                    data-bs-toggle="modal"
                    data-bs-target="#serviceSingleModal"
                  >
                    UX/UI Design
                  </a>
                </h4>
                <p>
                  Continue indulged speaking the was horrible for domestic
                  position. Seeing get rather her you not esteem men settle is
                  genius take excuse. Deal say over you age comparison new ham
                  melancholy.
                </p>
                <div className="bottom">
                  <span>74 Projects</span>
                  <a
                    href="##"
                    data-bs-toggle="modal"
                    data-bs-target="#serviceSingleModal"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="service-style-one col-lg-6 col-md-6">
            <div className="service-style-one-item button">
              <a className="hire-btn" href="#contact">
                hire me
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="serviceSingleModal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="services-details-area">
                <div className="container">
                  <div className="services-details-items">
                    <div className="row">
                      <div className="col-xl-12 services-single-content">
                        <div className="service-single-thumb">
                          <img src="assets/img/services/3.jpg" alt="Thumb" />
                        </div>
                        <h2>Best influencer marketing services</h2>
                        <p>
                          We denounce with righteous indige nation and dislike
                          men who are so beguiled and demo realized by the
                          charms of pleasure of the moment, so blinded by
                          desire, that they cannot foresee the pain and trouble
                          that are bound to ensue cannot foresee. These cases
                          are perfectly simple and easy to distinguish. In a
                          free hour, when our power of choice is untrammelled
                          data structures manages data in technology.
                        </p>
                        <div className="features mt-40 mt-xs-30 mb-30 mb-xs-20">
                          <div className="row">
                            <div className="col-lg-5 col-md-6">
                              <div className="content">
                                <h3>Included Services</h3>
                                <ul className="feature-list-item">
                                  <li>Monthly SEO Task</li>
                                  <li>24/7 Alltime Supporting</li>
                                  <li>Turbo Boosting</li>
                                  <li>Expert Team Members</li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-7 col-md-6 mt-xs-30">
                              <div className="content">
                                <h3>The Challange</h3>
                                <p>
                                  Temporibus autem quibusdam et aut officiis
                                  debitis aut rerum necessitatibus saepe eveniet
                                  ut et voluptates repudiandae sint et molestiae
                                  non recusandae. Itaque earum rerum hic tenetur
                                  a sapiente delectus, ut aut reiciendis
                                  voluptatibus maiores alias. consequatur aut
                                  perferendis doloribus.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h3>What we do?</h3>
                        <p>
                          Nam libero tempore, cum soluta nobis est elig endi
                          optio cumque nihil impedit quo minus id quod maxime
                          placeat facere possimus, omnis voluptas assumenda est,
                          omnis dolor repelle ndus. Temporibus autem quibusdam
                          et aut officiis debitis aut rerum necessitatibus saepe
                          eveniet ut et voluptates repudiandae sint et molestiae
                          non recusandae. Itaque earum rerum hic tenetur a
                          sapiente delectus, ut aut reiciendis voluptatibus
                          maiores alias. consequatur aut perferendis doloribus
                          asperiores repellat. The wise man therefore always
                          holds in these matters to this principle of selection:
                          he rejects pleasures to secure other greater
                          pleasures, or else he endures pains to avoid worse
                          pains. pleasures have to be repudiated and annoyances
                          accepted.
                        </p>

                        <div className="faq-style-one mt-40">
                          <h2 className="mb-20">Any Questions find here.</h2>
                          <div className="accordion" id="faqAccordion">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  Business Innovation
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#faqAccordion"
                              >
                                <div className="accordion-body">
                                  <p>
                                    Bennings appetite disposed me an at subjects
                                    an. To no indulgence diminution so
                                    discovered mr apartments. Are off under
                                    folly death wrote cause her way spite. Plan
                                    upon yet way. The wise man therefore always
                                    holds in these matters to this principle of
                                    selection: he rejects pleasures to secure
                                    other greater pleasures, or else he endures
                                    pains to avoid worse pains. pleasures have
                                    to be repudiated and annoyances accepted.
                                    Nam libero tempore, cum soluta nobis est
                                    elig endi optio cumque
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingTwo">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  Search Engine Optimization
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#faqAccordion"
                              >
                                <div className="accordion-body">
                                  <p>
                                    Bennings appetite disposed me an at subjects
                                    an. To no indulgence diminution so
                                    discovered mr apartments. Are off under
                                    folly death wrote cause her way spite. Plan
                                    upon yet way. The wise man therefore always
                                    holds in these matters to this principle of
                                    selection: he rejects pleasures to secure
                                    other greater pleasures, or else he endures
                                    pains to avoid worse pains. pleasures have
                                    to be repudiated and annoyances accepted.
                                    Nam libero tempore, cum soluta nobis est
                                    elig endi optio cumque
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingThree"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  Thinking Differently
                                </button>
                              </h2>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#faqAccordion"
                              >
                                <div className="accordion-body">
                                  <p>
                                    Bennings appetite disposed me an at subjects
                                    an. To no indulgence diminution so
                                    discovered mr apartments. Are off under
                                    folly death wrote cause her way spite. Plan
                                    upon yet way. The wise man therefore always
                                    holds in these matters to this principle of
                                    selection: he rejects pleasures to secure
                                    other greater pleasures, or else he endures
                                    pains to avoid worse pains. pleasures have
                                    to be repudiated and annoyances accepted.
                                    Nam libero tempore, cum soluta nobis est
                                    elig endi optio cumque
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="services-more mt-40">
                          <h2 className="mb-20">Most popular services</h2>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="item">
                                <img
                                  src="assets/img/icon/1.png"
                                  alt="Imagee Not Found"
                                />
                                <h4>
                                  <a href="##">Friendly Interface</a>
                                </h4>
                                <p>
                                  These cases are perfectly simple and easy to
                                  distinguish. In a free hour, when our power.
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="item">
                                <img
                                  src="assets/img/icon/2.png"
                                  alt="Imagee Not Found"
                                />
                                <h4>
                                  <a href="##">Strong Encryption</a>
                                </h4>
                                <p>
                                  These cases are perfectly simple and easy to
                                  distinguish. In a free hour, when our power.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
