import React from "react";

function Pricing() {
  return (
    <div className="pricing-style-one-area relative">
      <div className="container">
        <div className="row align-center">
          <div className="col-lg-12 col-xl-4">
            <div className="pricing-left">
              <h4 className="sub-title">Pricing</h4>
              <h2 className="title">The best pricing plans</h2>
              <ul className="list-rating mt-50">
                <li>
                  Seller communication level
                  <div className="rating">
                    <i className="fas fa-star"></i> 4.9
                  </div>
                </li>
                <li>
                  Recommend to a friend
                  <div className="rating">
                    <i className="fas fa-star"></i> 5
                  </div>
                </li>
                <li>
                  Deliver on time
                  <div className="rating">
                    <i className="fas fa-star"></i> 5
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-12 col-xl-7 offset-xl-1">
            <div className="pricing-style-one-items">
              <div
                className="nav nav-tabs pricing-tab-navs"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link"
                  id="nav-id-1"
                  data-bs-toggle="tab"
                  data-bs-target="#tab1"
                  type="button"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  <span>Monthly</span>
                </button>
                <button
                  className="nav-link active"
                  id="nav-id-2"
                  data-bs-toggle="tab"
                  data-bs-target="#tab2"
                  type="button"
                  role="tab"
                  aria-controls="tab2"
                  aria-selected="false"
                >
                  <span>Yearly</span>
                </button>
              </div>

              <div
                className="tab-content pricing-tab-content"
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade"
                  id="tab1"
                  role="tabpanel"
                  aria-labelledby="nav-id-1"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="pricing-style-one">
                        <i className="fab fa-wordpress"></i>
                        <h4>WordPress Development</h4>
                        <ul>
                          <li>1 Page with Elementor</li>
                          <li>Design Customization</li>
                          <li>Responsive Design</li>
                        </ul>
                        <div className="price">
                          <h2>
                            <sup>$</sup>48
                          </h2>
                        </div>
                        <a
                          className="btn mt-25 btn-sm circle btn-border light"
                          href="#contact"
                        >
                          Order Now
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="pricing-style-one active">
                        <i className="fab fa-react"></i>
                        <h4>Static frontend design</h4>
                        <ul>
                          <li>2 Page with React JS</li>
                          <li>Design implement</li>
                          <li>Responsive Design</li>
                        </ul>
                        <div className="price">
                          <h2>
                            <sup>$</sup>24
                          </h2>
                        </div>
                        <a
                          className="btn mt-25 btn-sm circle btn-dark"
                          href="#contact"
                        >
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="tab2"
                  role="tabpanel"
                  aria-labelledby="nav-id-2"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="pricing-style-one active">
                        <i className="fab fa-wordpress"></i>
                        <h4>WordPress Development</h4>
                        <ul>
                          <li>1 Page with Elementor</li>
                          <li>Design Customization</li>
                          <li>Responsive Design</li>
                        </ul>
                        <div className="price">
                          <h2>
                            <sup>$</sup>157
                          </h2>
                        </div>
                        <a
                          className="btn mt-25 btn-sm circle btn-dark"
                          href="#contact"
                        >
                          Order Now
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="pricing-style-one">
                        <i className="fab fa-react"></i>
                        <h4>Static frontend design</h4>
                        <ul>
                          <li>2 Page with React JS</li>
                          <li>Design implement</li>
                          <li>Responsive Design</li>
                        </ul>
                        <div className="price">
                          <h2>
                            <sup>$</sup>65
                          </h2>
                        </div>
                        <a
                          className="btn mt-25 btn-sm circle btn-border light"
                          href="#contact"
                        >
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
