import React from "react";

function Sidebar() {
  return (
    <div className="side-navbar-items">
      <div className="nav-wrapper">
        <div className="side-navbar">
          <div className="side-nav-menu">
            <a className="smooth-menu" href="#home">
              <img src="assets/img/logo-icon.png" alt="Logo" />
            </a>
          </div>
          <div className="nav-toggle">
            <img src="assets/img/menu-white.svg" alt="Menu" />
          </div>
          <div className="author-social">
            <input
              type="checkbox"
              id="toggle"
              className="share-toggle"
              hidden={true}
            />
            <label for="toggle" className="share-button facebook">
              <i className="fas fa-plus"></i>
            </label>
            <a
              href="https://www.linkedin.com/in/umairabbas786/"
              target="_blank"
              rel="noreferrer"
              className="share-icon linkedin"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="https://github.com/umairabbas786"
              target="_blank"
              rel="noreferrer"
              className="share-icon github"
            >
              <i className="fab fa-github"></i>
            </a>
            <a
              href="https://www.instagram.com/Umair.abbas786"
              target="_blank"
              rel="noreferrer"
              className="share-icon instagram"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://facebook.com/umair.abbas786"
              target="_blank"
              rel="noreferrer"
              className="share-icon facebook"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="nav-side">
          <div className="nav-items simple-menu">
            <div className="nav-toggle">
              <i className="fas fa-times"></i>
            </div>
            <div className="nav-item dropdown">
              <a className="smooth-menu" href="#home">
                Home
              </a>
              <div className="nav-item-wrapper"></div>
            </div>
            <div className="nav-item">
              <a className="smooth-menu" href="#services">
                Services
              </a>
              <div className="nav-item-wrapper"></div>
            </div>
            <div className="nav-item">
              <a className="smooth-menu" href="#about">
                About
              </a>
              <div className="nav-item-wrapper"></div>
            </div>
            <div className="nav-item">
              <a className="smooth-menu" href="#portfolio">
                Portfolio
              </a>
              <div className="nav-item-wrapper"></div>
            </div>
            <div className="nav-item">
              <a className="smooth-menu" href="#testimonials">
                Testimonials
              </a>
              <div className="nav-item-wrapper"></div>
            </div>
            <div className="nav-item">
              <a className="smooth-menu" href="#contact">
                Contact
              </a>
              <div className="nav-item-wrapper"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
