import About from "./components/About";
import Banner from "./components/Banner";
import Brands from "./components/Brands";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Portfolio from "./components/Portfolio";
import PreLoader from "./components/PreLoader";
import Pricing from "./components/Pricing";
import Services from "./components/Services";
import Sidebar from "./components/Sidebar";
import Testimonials from "./components/Testimonials";

function App() {
  return (
    <div className="App">
      <PreLoader/>
      <Header/>
      <div className="main-wrapper">
        <Sidebar/>
        <Banner/>
        <Services/>
        <About/>
        <Pricing/>
        <Portfolio/>
        <Testimonials/>
        <Brands/>
        <Contact/>
      </div>
    </div>
  );
}

export default App;
