import React from "react";

function About() {
  return (
    <div id="about" className="about-style-one-area default-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="about-style-one-thumb">
              <img src="assets/img/illustration/2.png" alt="Umair Abbas" />
              <div className="curve-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 150 150"
                  version="1.1"
                >
                  <path id="textPath" d="M 0,75 a 75,75 0 1,1 0,1 z"></path>
                  <text>
                    <textPath href="#textPath">Years of experience</textPath>
                  </text>
                </svg>
                <h4>7+</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 mt-50">
            <div className="about-style-one-info">
              <h3 className="sub-title">About Me</h3>
              <h2 className="title">
                I Develop Solutions <br /> that help people
              </h2>
              <p>
                As a creative designer, I am deeply dedicated to my craft and
                passionate about delivering exceptional work. With Weblium
                templates, which are meticulously crafted through extensive
                niche research and seamlessly integrate the latest web design
                trends, creating a stunning website tailored to your business
                has never been easier. By selecting a template and incorporating
                your distinctive content, you can effortlessly achieve a
                visually appealing website with optimal navigation perfectly
                suited to your business needs.
              </p>
              <a href="#contact" className="btn-standard mt-10 smooth-menu">
                Donwload CV
              </a>
              <div className="about-item">
                <h3>
                  <i className="fas fa-user"></i> Biography
                </h3>
                <ul className="biography-table">
                  <li>
                    <h5>Name</h5>
                    <p>Umair Abbas</p>
                  </li>
                  <li>
                    <h5>Birthday</h5>
                    <p>10 March, 2000</p>
                  </li>
                  <li>
                    <h5>Email</h5>
                    <p>umairabbass786@gmail.com</p>
                  </li>
                  <li>
                    <h5>Skype</h5>
                    <p>umairabbass786_1</p>
                  </li>
                </ul>
              </div>
              <div className="about-item">
                <h3>
                  <i className="fas fa-rocket"></i> Personal Skill
                </h3>
                <ul className="skill-items mt-10">
                  <li>
                    <div className="icon">
                      <i className="fab fa-laravel"></i>
                    </div>
                    <div className="content">
                      <span>75%</span>
                      <h5>Object oriented programming</h5>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fab fa-react"></i>
                    </div>
                    <div className="content">
                      <span>90%</span>
                      <h5>Front-End with React</h5>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fab fa-html5"></i>
                    </div>
                    <div className="content">
                      <span>89%</span>
                      <h5>Advance frontend development</h5>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="about-item">
                <h3>
                  <i className="fas fa-graduation-cap"></i> Education
                </h3>
                <ul className="education-table">
                  <li>
                    <h4>BS - Computer Science</h4>
                    <h5>Unniversity of Central Punjab</h5>
                    <span>2019 - 2023</span>
                    <p>
                      The training provided by universities in order to prepare
                      people to work in various sectors of the economy or areas
                      of culture.
                    </p>
                  </li>
                  <li>
                    <h4>A Levels</h4>
                    <h5>The Beacon House School System</h5>
                    <span>2017 - 2019</span>
                    <p>
                      The education offered by colleges to equip individuals for
                      employment in diverse sectors of the economy or cultural
                      domains.
                    </p>
                  </li>
                  <li>
                    <h4>O' Levels</h4>
                    <h5>The City School</h5>
                    <span>2015 - 2017</span>
                    <p>
                      The education provided by schools to prepare individuals
                      for careers in different sectors of the economy or
                      cultural fields.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
